<template>
  <v-card elevation="0" v-if="!loading" class="w-100" ref="main-card">
    <div class="mt-4">
      <loadingProgress :loading="data_loading" />
    </div>
    <v-data-table
      class="simpleTable"
      :headers="headers"
      :items="data"
      :items-per-page="15"
      :loading="data_loading"
      :options.sync="pagination"
      :server-items-length="data_total"
      light
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50],
        showFirstLastPage: true,
      }"
      :no-data-text="favorite_page ? 'No favorites products available' : 'No data available'"
      @update:options="sortTable"
      @update:items-per-page="setItemPerPage"
      multi-sort
      :item-class="notFound"
    >
      <template
        v-for="(key, i) in headers"
        v-slot:[`header.${key.value}`]="{ header: TableHeader }"
      >
        <div class="sm-text sm-text text-truncate" style="max-width: 80px" v-bind:key="i">
          {{ TableHeader.text }}
        </div>
      </template>
      <template v-slot:header.select v-if="allowMultiSelect()">
        <v-btn icon @click="selectAll()">
          <v-icon color="error" size="medium" v-if="selectedMatchs.length"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon color="grey" size="medium" v-else>mdi-checkbox-blank-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.select="{ item }" v-if="allowMultiSelect()">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="align-self-center mr-2"
                icon
                :color="item.select ? 'error' : 'grey'"
                @click.stop="selectMatch(item)"
              >
                <v-icon v-if="!item.select" :key="tableComponentKey" size="medium"
                  >mdi-checkbox-blank-outline</v-icon
                >
                <v-icon v-if="item.select" :key="tableComponentKey" size="medium"
                  >mdi-checkbox-marked</v-icon
                >
              </v-btn>
            </template>
            <span>remove thid product from favorite products</span>
          </v-tooltip>
          <div class="w-100 d-flex justify-center" v-if="item[`product_image`]">
            <img
              @click.stop="showPriceChart(item)"
              class="product-image cursor-pointer"
              :src="buildImageSrc(item[`product_image`])"
              @error="getCompetitorImage($event, item)"
            />
          </div>
        </div>
      </template>
      <template v-slot:item.favorite="{ item }">
        <div class="d-flex">
          <v-tooltip bottom v-if="favorite_page">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="align-self-center mr-2"
                icon
                color="error"
                @click.stop="removeFavorite(item)"
              >
                <v-icon>$heart_off</v-icon>
              </v-btn>
            </template>
            <span>remove thid product from favorite products</span>
          </v-tooltip>
          <div class="w-100 d-flex justify-center" v-if="item[`product_image`]">
            <img
              class="product-image"
              :src="buildImageSrc(item[`product_image`])"
              @error="getCompetitorImage($event, item)"
            />
          </div>
        </div>
      </template>
      <template v-slot:item.match_user_ref="{ item }">
        <div
          class="sm-text text--secondary font-weight-bold"
          v-if="account != '654b792b2071861acbbc3331'"
        >
          {{ item.match_user_ref || '' }}
        </div>
        <div class="sm-text text--secondary font-weight-bold" v-else>
          {{ item.product_ean || '' }}
        </div>
        <span v-if="item.verified">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon x-small color="grey" class="mr-2">
                <v-icon color="success">mdi-check-decagram</v-icon>
              </v-btn>
            </template>
            <span>Product match confirmed</span>
          </v-tooltip>
        </span>
        <span v-if="!item.verified">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                color="grey"
                class="mr-1"
                @click.stop="showValidateModal(item)"
              >
                <v-icon color="warning">mdi-alert-circle-check-outline</v-icon>
              </v-btn>
            </template>
            <span>Confirm product match</span>
          </v-tooltip>
        </span>
        <span v-if="item.model">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small color="grey" class="mr-1">
                <span class="text--disabled font-weight-bold">M</span>
              </v-btn>
            </template>
            <span>MPN code available</span>
          </v-tooltip>
        </span>
        <span v-if="item.product_ean">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small color="grey" class="mr-1">
                <span class="text--disabled font-weight-bold">E</span>
              </v-btn>
            </template>
            <span>EAN code available {{ item.product_ean }}</span>
          </v-tooltip>
        </span>
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                color="grey"
                class="mr-1"
                @click.stop="showPriceChart(item)"
              >
                <v-icon x-small>mdi-chart-line</v-icon>
              </v-btn>
            </template>
            <span>Show changes history</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.product_name="{ item }">
        <div @click.stop="showPriceChart(item)" class="more-info">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-truncate sm-text text--secondary font-weight-bold"
                :data-match-id="item['match_id']"
                v-on="on"
                v-bind="attrs"
              >
                <span>
                  {{ cutText(item['product_name'], 30) }}
                </span>
              </div>
            </template>
            <span>{{ item['product_name'] }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="sm-text">
                {{ cutText(item.product_brand, 50) }}
              </span>
            </template>
            <span>{{ item.product_brand }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.price="{ item }">
        <span
          class="sm-text"
          v-html="
            simplePriceFormatter(item[user_website.alias], item.hight_price, item.low_price, false)
          "
        ></span>
      </template>
      <template v-slot:item.price_index="{ item }">
        <span class="text--disabled font-weight-bold sm-text">{{ item.price_index }} %</span>
      </template>
      <template v-slot:item.hight_price="{ item }">
        <v-row>
          <v-col class="" cols="12">
            <span
              class="text-danger font-weight-bold text-small sm-text"
              v-html="singlePriceHandler(item.hight_price)"
            >
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.low_price="{ item }">
        <v-row>
          <v-col class="" cols="12">
            <span
              class="text-success font-weight-bold text-small sm-text"
              v-html="singlePriceHandler(item.low_price)"
            >
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-for="(key, i) in availableWebsites" v-slot:[`item.${key}`]="{ item }">
        <v-row v-bind:key="i">
          <v-col class="" cols="12 pa-0">
            <a v-if="item[key]" :href="item[key]['url']" target="_blank">
              <div>
                <span v-html="formatProductOffer(item[key], item, key)"></span>
                <!-- <span class="sm-text text--secondary font-weight-bold">
                  <v-icon x-small>mdi-google</v-icon>
                </span> -->
              </div>
            </a>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.menu="{ item }">
        <v-menu open-on-hover auto>
          <template v-slot:activator="{ on: menu }" class="pa-4">
            <v-btn class="action-icon" v-on="{ ...menu }" medium icon>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-0" flat>
            <v-card-text>
              <v-row>
                <v-col cols="3" class="pa-1">
                  <v-btn small icon>
                    <v-icon size="medium" @click.stop="showPriceChart(item)">$eye_outline</v-icon>
                  </v-btn>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="3" class="pa-1">
                  <v-btn small icon>
                    <v-icon size="medium" @click.stop="confirmDelete(item)">$delete</v-icon>
                  </v-btn>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="3" class="pa-1">
                  <v-btn small icon>
                    <v-icon size="medium" @click.stop="showEditModal(item)">$edit</v-icon>
                  </v-btn>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="3" class="pa-1">
                  <v-btn small icon>
                    <v-icon
                      size="medium"
                      :color="item.favorite ? 'error' : 'grey'"
                      @click.stop="setFavorite(item)"
                      >$heart</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'simpleComparePricesTable',
    props: [
      'items',
      'filter',
      'loading',
      'favorite_page',
      'exclusive_page',
      'changed',
      'total',
      'selected_price_index',
      'component_name',
    ],
    components: {},
    data() {
      return {
        account: localStorage.getItem('account_id'),
        isDevMode: false,
        tableComponentKey: 0,
        data_loading: false,
        user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
        pagination: { rowsPerPage: 15, totalItems: 0 },
        searchParams: {},
        from: 0,
        size: 15,
        availableWebsites: [],
        headers: [
          {
            text: 'Id',
            value: 'match_user_ref',
            sortable: false,
            width: '130px',
          },
          {
            text: 'Product',
            value: 'product_name',
            sortable: false,
          },
          {
            text: 'Price',
            value: 'price',
            align: 'left',
            width: '100px',
            sortable: false,
          },
          {
            text: 'Index %',
            value: 'price_index',
            align: 'left',
            width: '100px',
            sortable: false,
          },
          {
            text: 'Min',
            value: 'low_price',
            sortable: false,
          },
          {
            text: 'Max',
            value: 'hight_price',
            sortable: false,
          },
        ],
        data: [],
        selectedMatchs: [],
        data_total: 0,
        matchsStats: false,
        table_sorts: [],
        price_index: null,
      }
    },
    computed: {},
    mounted() {
      this.isDevMode = process.env.VUE_APP_MODE === ''

      /**
       * show the current component
       */
      if (this.allowMultiSelect()) {
        this.headers.unshift({
          text: '',
          value: 'select',
          sortable: false,
          width: '50px',
        })
      }

      if (this.favorite_page) {
        this.headers.unshift({
          text: '',
          value: 'favorite',
          sortable: false,
          width: '50px',
        })
      }

      this.headers.unshift({
        text: '',
        value: 'menu',
        sortable: false,
        width: '50px',
      })

      this.fixTablsColumns()
    },
    methods: {
      fixTablsColumns() {
        var table = document.getElementsByTagName('table')[0]
        var thCells = table.querySelectorAll('thead th')
        var fixedColumns = []
        var css = ''

        // Calculate and generate CSS for fixing columns
        var leftPosition = 0
        for (var i = 0; i < thCells.length; i++) {
          if (i < 6) {
            css += '.fixed-column:nth-child(' + (i + 1) + ') { left: ' + leftPosition + 'px; }\n'
            // Apply the fixed-column class to the first six cells
            thCells[i].classList.add('fixed-column')
            fixedColumns.push(thCells[i])
          }
          leftPosition += thCells[i].offsetWidth
        }

        // Inject CSS into <style> tag
        var styleTag = document.createElement('style')
        styleTag.innerHTML = css
        document.head.appendChild(styleTag)

        // Add scroll event listener
        table.addEventListener('scroll', function () {
          var scrollTop = table.scrollTop
          var scrollLeft = table.scrollLeft

          // Apply scroll position to the fixed columns
          fixedColumns.forEach(function (column) {
            column.style.top = scrollTop + 'px'
          })

          // Apply scroll position to the header row
          var headerRow = table.querySelector('thead tr')
          headerRow.style.transform = 'translateX(-' + scrollLeft + 'px)'
        })
      },
      cutText(text, length) {
        return text.length > length ? text.substring(0, length) + '...' : text
      },
      forceUpdate() {
        this.tableComponentKey++
      },
      allowMultiSelect() {
        return ['/pricing', '/compare', '/exclusiveproducts'].includes(
          window.location.pathname.toLowerCase(),
        )
      },
      buildImageSrc(item) {
        if (item && item.match(/data:image\/.*/g)) {
          return item.match(/data:image\/.*/g)[0]
        }
        return item
      },
      confirmDelete(item) {
        this.$emit('confirm-delete', item)
      },
      showPriceChart(item) {
        this.$emit('show-price-chart', item)
      },
      showEditModal(item) {
        this.$emit('show-edit-modal', item)
      },
      showValidateModal(item) {
        this.$emit('show-validate-modal', {
          item,
          data: this.data,
          pagination: {
            from: this.from,
            size: this.size,
          },
          searchParams: this.searchParams,
        })
      },
      setItemPerPage(val) {
        this.pagination.rowsPerPage = val
      },
      getInvalidProductsLength(item) {
        return Object.keys(item)
          .map((key) => {
            if (item[key] && item[key].product) {
              return item[key].product
            }
          })
          .filter((product) => product && !product.validated).length
      },
      getInvalidProducts(item) {
        const invalidProducts = this.getInvalidProductsLength(item)
        return invalidProducts ? `${invalidProducts} need validation` : ''
      },
      sortTable(sorts) {
        this.table_sorts = []
        let sort_by = sorts.sortBy
        let sort_order = sorts.sortDesc
        sort_by.map((elt, index) => {
          this.table_sorts.push({
            sort_by: elt,
            order: sort_order[index] ? 'desc' : 'asc',
          })
        })
      },
      async setFavorite(item) {
        let index = this.data.indexOf(item)
        this.data[index].favorite = !this.data[index].favorite
        await axios
          .post(`/api/products/favorite`, {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
            id_match: item.match_id,
            favorite: this.data[index].favorite,
          })
          .then((res) => {
            if (res.data.status) {
              if (res.data.operation)
                this.$emit('show-notification', {
                  msg: `the product named ( ${this.data[index].product_name} ) has been successfully added to favorites, check your favorites list`,
                  type: 'success',
                  icon: 'heart',
                })
              else
                this.$emit('show-notification', {
                  msg: `the product named ( ${this.data[index].product_name} ) has been successfully removed from favorites, check your favorites list`,
                  type: 'error',
                  icon: 'heart-off',
                })
            }
          })
      },
      async getProducts() {
        //let filter = this.filter != undefined ? this.filter : {}
        //console.log('filter from propr : ', filter)
        let filter = localStorage.getItem('filter')
          ? JSON.parse(localStorage.getItem('filter'))
          : { source: '' }
        this.headers = this.headers.slice(0, 8)
        this.data = []
        this.data_loading = true
        this.from = this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage
        this.size = this.pagination.rowsPerPage

        if (!filter.source) filter.source = ''

        this.searchParams = {
          ...filter,
          account: localStorage.getItem('account_id'),
          user: localStorage.getItem('user_id'),
          table_sorts: this.table_sorts,
          favorite_only: this.favorite_page,
          exclusive_only: this.exclusive_page,
          price_index: this.price_index,
          component: this.component_name,
          raw_price_index: filter.price_index,
          page: this.pagination.page,
          rowsPerPage: this.pagination.rowsPerPage,
          from: this.from,
          size: this.size,
        }

        if (this.searchParams.key) {
          this.searchParams.page = 1
          this.searchParams.from = 0
        }

        this.data = await axios
          .get('/api/products/list', {
            params: {
              ...this.searchParams,
            },
          })
          .then((res) => {
            if (res) {
              this.data_total = res.data.total
              this.addCompetitorsItems(res.data.data)
              return res.data.data
            }
            return []
          })
          .catch((err) => {
            console.log(err)
            console.error('comparePricesTable - getProducts')
            return []
          })
        this.data_loading = false
        this.selectedMatchs = []
      },
      async selectAll() {
        if (this.selectedMatchs.length > 0) {
          this.selectedMatchs = []
          for (const elt of this.data) {
            elt.select = false
          }
        } else {
          for (const elt of this.data) {
            elt.select = true
            this.selectedMatchs.push(elt)
          }
        }
        this.forceUpdate()
        this.$emit('select-matchs', this.selectedMatchs)
      },
      async selectMatch(item) {
        item.select = !item.select
        if (item.select) {
          this.selectedMatchs.push(item)
        } else {
          this.selectedMatchs = this.selectedMatchs.filter((elt) => elt.match_id != item.match_id)
        }
        this.$emit('select-matchs', this.selectedMatchs)
      },
      async removeFavorite(item) {
        this.$emit('confirm-unfavorite', item)
      },
      getCompetitorImage(e, match) {
        let image = ''
        const website = Object.keys(match).find(
          (key) => match[key].product && match[key].product.image && match[key].product.validated,
        )
        if (website) image = match[website].product.image
        e.target.src = image
      },
      addCompetitorsItems(data) {
        let filterWithThisWebsite = []

        if (
          this.filter &&
          this.filter.competitors_websites &&
          this.filter.competitors_websites.length
        ) {
          filterWithThisWebsite = this.filter.competitors_websites
        }

        data.forEach((datum) => {
          Object.entries(datum).forEach(([key, value]) => {
            if (typeof value == 'object') {
              if (value.product) {
                datum[`_${key}`] = value.product.offers.length ? value.product.offers[0] : {}
                datum[`_${key}`]['url'] = value.product.url
                datum[`_${key}`]['isGoogleShoppingProduct'] = value.product.isGoogleShoppingProduct
                if (!this.headers.find((header) => header.text == `${key}`)) {
                  if (filterWithThisWebsite.length) {
                    if (filterWithThisWebsite.includes(key)) {
                      this.availableWebsites.push(`_${key}`)
                      this.headers.push({
                        text: `${key}`,
                        value: `_${key}`,
                        sortable: false,
                        class: 'pa-1',
                        width: '100px',
                        align: 'left',
                      })
                    }
                  } else {
                    this.availableWebsites.push(`_${key}`)
                    this.headers.push({
                      text: `${key}`,
                      value: `_${key}`,
                      sortable: false,
                      width: '100px',
                      class: 'pa-1',
                      align: 'left',
                    })
                  }
                }
              }
            }
          })
        })
      },
      notFound(item) {
        if (item[this.user_website.alias] && item[this.user_website.alias].product.not_found)
          return 'not_found_product'
      },
    },
    watch: {
      selectedMatchs: {
        handler() {},
      },
      pagination: {
        handler(newV, old) {
          try {
            this.getProducts().finally(() => {
              if (newV.page && old.page && newV.page != old.page) {
                this.$refs['main-card'].$el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            })
          } catch (error) {
            console.log('pagination', error)
          }
        },
        deep: true,
      },
      changed: {
        handler() {
          this.price_index = undefined
          this.getProducts()
        },
      },
      selected_price_index: {
        handler() {
          this.price_index = this.selected_price_index
          this.getProducts()
        },
      },
      total: {
        handler() {
          this.data_total = this.total
        },
      },
    },
  }
</script>
<style scoped>
  .product-image {
    object-fit: contain;
    max-width: 100;
    max-height: 50px;
    background-size: cover;
  }
  .more-info {
    cursor: pointer;
  }
  .brand-text {
    font-size: 1rem;
    font-weight: 600;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
</style>
<style>
  .v-menu__content {
    box-shadow: none !important;
    border: 1px solid #e0e0e0 !important;
  }

  .simpleTable thead tr th:first-child,
  .simpleTable tbody tr td:first-child {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background: white;
  }
</style>
