<template>
  <div>
    <v-card outlined class="card-height" v-bind:key="renderOccurence">
      <v-card-title class="pb-0">
        <v-menu open-on-click bottom offset-y>
          <template v-slot:activator="{ on: menu }" class="pa-0">
            <div v-on="{ ...menu }" style="cursor: pointer">
              <span v-if="dataType == 'price_index'" class="sm-text text--secondary ml-3"
                >Price index
                <span>
                  <v-icon small class="ml-1" color="grey">mdi-chevron-down</v-icon>
                </span>
              </span>
              <span v-if="dataType == 'price_changes'" class="sm-text text--secondary ml-3">
                Price changes
                <span>
                  <v-icon small class="ml-1" color="grey">mdi-chevron-down</v-icon>
                </span>
              </span>
            </div>
          </template>
          <v-list dense small class="pa-0">
            <v-list-item class="sm-text text--secondary" @click="dataType = 'price_index'">
              <v-list-item-content
                :class="{ 'font-weight-bold': dataType == 'price_index' }"
                color="grey"
              >
                Price index
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="sm-text text--secondary" @click="dataType = 'price_changes'">
              <v-list-item-content
                :class="{ 'font-weight-bold': dataType == 'price_changes' }"
                color="grey"
              >
                Price changes
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          dense
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey" icon light @click="reset" class="mx-2">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-icon
              v-bind="attrs"
              v-on="on"
              medium
              class="ml-auto"
              @click="menu = !menu"
              color="grey"
              >mdi-calendar-range-outline</v-icon
            >
          </template>
          <v-date-picker small v-model="dates" :max="max_date" no-title dense range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="changeRange"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-card-title>
      <div v-if="hoveredData.value">
        <span class="font-weight-bold ml-3 text--secondary pl-4">
          {{ hoveredData.value }}
          <span v-if="dataType == 'price_index'">%</span>
          <span v-if="dataType == 'price_changes'">product</span>
        </span>
        <span class="sm-text ml-2 text--secondary">{{ fullDateToShort(hoveredData.label) }}</span>
      </div>
      <v-card-text style="" class="d-flex">
        <v-col cols="12" class="ma-0" v-if="loading">
          <loadingProgress :loading="loading" text="Loading price index data..." />
        </v-col>
        <v-row v-show="!loading">
          <v-col cols="12" sm="12" md="12" class="d-flex align-center justify-center">
            <canvas
              ref="priceindexchart"
              id="priceindexchart"
              style="width: 100% !important"
              height="180px"
            ></canvas>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="" v-if="statistics.labels.length">
            <span class="text--secondary sm-text d-flex justify-end">
              Updated {{ dateToDayName(statistics.labels[statistics.labels.length - 1]) }}
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <chartPriceIndexHistoryModal
      :show="chart_price_index_history_modal"
      :component_name="component_name"
    />
  </div>
</template>
<script>
  import Chart from 'chart.js'
  import axios from 'axios'
  import chartPriceIndexHistoryModal from './chartPriceIndexHistoryModal'
  import moment from 'moment'
  export default {
    props: ['component_name'],
    components: {
      chartPriceIndexHistoryModal,
    },
    data() {
      return {
        renderOccurence: 0,
        loading: false,
        dataType: 'price_index',
        menu: false,
        myChart: null,
        max_date: moment(Date.now()).format('YYYY-MM-DD'),
        dates: [],
        gradient: '',
        gradient2: '',
        chart_price_index_history_modal: false,
        statistics: {
          labels: [],
          data: [],
          price_index: 0,
          changes_count: 0,
        },
        // maxAndMin: {
        //   max: 100,
        //   min: -100,
        // },
        hoveredData: {
          value: '',
          label: '',
        },
      }
    },
    mounted() {
      this.initHistoryChart()
    },
    computed: {
      dateRangeText() {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
      reset() {
        this.dates = []
        this.changeRange()
      },
      async changeRange() {
        let data =
          this.dataType == 'price_index'
            ? await this.getStatistics()
            : await this.getPricesChangesHistory()
        let labels = []
        let dataset_data = []
        data.map((datum) => {
          labels.push(datum.date)
          dataset_data.push(
            this.dataType == 'price_index' ? parseFloat(datum.price_index.toFixed(2)) : datum.count,
          )
        })
        this.myChart.data.labels = labels
        this.myChart.data.datasets[0].data = dataset_data
        this.myChart.update()
      },
      dateToDayName(date) {
        //date format YYYY-MM-DD
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const dateParts = date.split('-')
        const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
        //if date equal today return 'Today'
        //if date equal yesterday return 'Yesterday'

        if (dateObj.toDateString() === new Date().toDateString()) return 'Today'
        if (
          dateObj.toDateString() ===
          new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
        )
          return 'Yesterday'
        return days[dateObj.getDay()]
      },
      fullDateToShort(date) {
        //the defualt date format YYYY-MM-DD
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]

        const dateParts = date.split('-')
        const month = months[parseInt(dateParts[1]) - 1]
        return `${month} ${dateParts[2]}`
      },
      showPriceHistory() {
        this.chart_price_index_history_modal = !this.chart_price_index_history_modal
      },
      async initHistoryChart() {
        if (this.myChart) {
          /**
           * clear labels and dataset and update it with new data
           */
          this.myChart.data.labels = this.statistics.labels
          this.myChart.data.datasets[0].data = []
          this.myChart.update()
        }

        if (localStorage.getItem('price_index_dataType'))
          this.dataType = localStorage.getItem('price_index_dataType')
        else this.dataType = 'price_index'

        this.statistics = {
          labels: [],
          data: [],
          price_index: 0,
          changes_count: 0,
        }

        const valueProperty = this.dataType === 'price_index' ? 'price_index' : 'count'
        const data =
          this.dataType === 'price_index'
            ? await this.getStatistics()
            : await this.getPricesChangesHistory()
        const labels = []
        let datasets = [
          {
            label: '',
            data: [],
            borderSkipped: false,
            fill: false,
            borderColor: '',
            hoverBorderWidth: 1,
            borderWidth: 1,
          },
        ]

        const colors = data.map((value) => {
          labels.push(value.date)
          if (value[valueProperty]) {
            this.statistics.data.push(value[valueProperty].toFixed(2))
          }
          if (valueProperty === 'price_index') {
            return value.price_index < 0 ? '#63ac75' : value.price_index > 0 ? '#bc3343' : '#20c2de'
          } else {
            return '#20c2de'
          }
        })

        this.statistics.labels = labels

        datasets[0].pointBorderColor = colors.length ? colors[colors.length - 1] : '#20c2de'
        datasets[0].pointBackgroundColor = datasets[0].pointBorderColor
        datasets[0].pointHoverBackgroundColor = colors
        datasets[0].pointHoverBorderColor = colors
        datasets[0].borderColor = colors[colors.length - 1]

        this.hoveredData.label = labels[labels.length - 1]
        if (valueProperty === 'price_index') {
          this.statistics.price_index = this.statistics.data[this.statistics.data.length - 1]

          this.hoveredData.value = this.statistics.price_index
            ? parseFloat(this.statistics.price_index).toFixed(2)
            : 0
        }

        if (valueProperty === 'count') {
          this.statistics.changes_count = this.statistics.data[this.statistics.data.length - 1]
          this.hoveredData.value = this.statistics.changes_count
        }

        datasets[0].data = this.statistics.data

        var ctx = document.getElementById('priceindexchart')

        ctx.addEventListener('mouseleave', (event) => {
          if (valueProperty === 'price_index') {
            this.hoveredData.value = this.statistics.price_index
          }
          if (valueProperty === 'count') {
            this.hoveredData.value = this.statistics.changes_count
          }
          this.hoveredData.label = labels[labels.length - 1]
        })

        //reset chart

        this.myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            responsive: false,
            hover: {
              mode: 'index',
              intersect: false,
            },
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 4,
                right: 4,
                top: 0,
                bottom: 0,
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: true,
                    maxRotation: 369,
                    minRotation: 360,
                    callback: (value, index, values) => {
                      if (index === values.length - 1) return this.fullDateToShort(value)
                      else if (index === 0) return this.fullDateToShort(value)
                      else return ''
                    },
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                    beginAtZero: false,
                    // max: this.maxAndMin.max,
                    // min: this.maxAndMin.min,
                  },
                  gridLines: {
                    display: false,
                    border: false,
                  },
                },
              ],
            },
            onLeave: (event, chart) => {
              this.hoveredData.value = ''
              this.hoveredData.label = ''
            },
            onHover: (event, active, chart) => {
              if (active.length) {
                let first = active[0]
                let setIndex = first._datasetIndex
                let index = first._index
                let value = first._chart.chart.data.datasets[setIndex].data[index]
                let label = first._chart.chart.data.labels[index]
                this.hoveredData.value = value
                this.hoveredData.label = label
              }
            },
          },
        })
      },
      async getPricesChangesHistory() {
        this.$refs.menu.save(this.dates)
        let from, to, temp1, temp2

        if (this.dates.length) {
          temp1 = this.dates[0].split('-')
          let x = new Date(
            Date.UTC(temp1[0], temp1[1] - 1, temp1[2] != undefined ? temp1[2] : 1, 0, 0, 0),
          )

          temp2 = this.dates[1] == undefined ? this.dates[0].split('-') : this.dates[1].split('-')
          let y = new Date(
            Date.UTC(temp2[0], temp2[1] - 1, temp2[2] != undefined ? temp2[2] : 1, 22, 0, 0),
          )

          if (+x <= +y) {
            from = x.getTime()
            to = y.getTime()
          } else {
            y.setHours(0)
            x.setHours(22)
            from = y.getTime()
            to = x.getTime()
          }
        } else {
          to = 0
          from = 0
        }
        this.loading = true
        return await axios
          .get(`/api/products/priceschangeshistory`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              search_filter: localStorage.getItem('filter'),
              full: false,
              from: from,
              to: to,
            },
          })
          .then((res) => {
            return res.data.statisticsData
          })
          .finally(() => {
            this.loading = false
          })
      },
      async getStatistics() {
        this.$refs.menu.save(this.dates)
        let from, to, temp1, temp2

        if (this.dates.length) {
          temp1 = this.dates[0].split('-')
          let x = new Date(
            Date.UTC(temp1[0], temp1[1] - 1, temp1[2] != undefined ? temp1[2] : 1, 0, 0, 0),
          )

          temp2 = this.dates[1] == undefined ? this.dates[0].split('-') : this.dates[1].split('-')
          let y = new Date(
            Date.UTC(temp2[0], temp2[1] - 1, temp2[2] != undefined ? temp2[2] : 1, 22, 0, 0),
          )

          if (+x <= +y) {
            from = x.getTime()
            to = y.getTime()
          } else {
            y.setHours(0)
            x.setHours(22)
            from = y.getTime()
            to = x.getTime()
          }
        } else {
          to = 0
          from = 0
        }
        this.loading = true
        return await axios
          .get(`/api/products/statisticshistory`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              search_filter: localStorage.getItem('filter'),
              full: false,
              from: from,
              to: to,
            },
          })
          .then((res) => {
            return res.data.statisticsData
          })
          .finally(() => {
            this.loading = false
          })
      },
    },

    watch: {
      dataType: {
        handler: function (val) {
          if (localStorage.getItem('price_index_dataType') == val) return
          localStorage.setItem('price_index_dataType', val)
          this.renderOccurence++
          this.initHistoryChart()
        },
      },
    },
  }
</script>
<style scoped>
  .card-height {
    height: 320px !important;
  }
  .crawlo-text-size {
    font-size: 18px !important;
  }
  .hightprice {
    color: #db4e4e !important;
  }
  .lowprice {
    color: #33de40 !important;
  }
</style>
